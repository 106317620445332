<template>
	<div id="User">
		<div class="Title ">
			<p > 个 人 中 心  </p>
			<div class="Title-lower"></div>
		</div>
		<div class="User">
			<div class="User_nav">
<!--				<div class="User_top">-->
<!--					<img src="@/assets/images/NavHeader/logo.png" alt="">-->
<!--				</div>-->
				<p :class="{'active' : this.$route.name == 'About'}"
					 @click="ToRoute('/About')">
<!--					<img src="@/assets/images/NavHeader/GeR.png" alt="">-->
					个人中心</p>
				<p :class="{'active' : this.$route.name == 'Warehouse'}"
					 @click="ToRoute('/Warehouse')">
<!--					<img src="@/assets/images/NavHeader/BeiB.png" alt="">-->
					个人背包</p>
				<p :class="{'active' : this.$route.name == 'deliverGoods'}"
					 @click="ToRoute('/deliverGoods')">
					<!--					<img src="@/assets/images/NavHeader/BeiB.png" alt="">-->
					正在发货</p>
				<p :class="{'active' : this.$route.name == 'completed'}"
					 @click="ToRoute('/completed')">
					<!--					<img src="@/assets/images/NavHeader/BeiB.png" alt="">-->
					发货完成</p>
				<p :class="{'active' : this.$route.name == 'vipActive'}"
					 @click="ToRoute('/vipActive')">
					<!--					<img src="@/assets/images/NavHeader/BeiB.png" alt="">-->
					奖励明细</p>
<!--				<p :class="{'active' : this.$route.name == 'Extension'}" @click="ToRoute('/Extension')"><img src="@/assets/images/NavHeader/TuiG.png" alt="">会员推广</p>-->
<!--				<p :class="{'active' : this.$route.name == 'ChangePassword'}" @click="ToRoute('/ChangePassword')"><img src="@/assets/images/NavHeader/XiuG.png" alt="">修改密码</p>-->
<!--				<p @click="SignOut"><img src="../../assets/images/NavHeader/TuiC.png" alt="">退出登录</p>-->
			</div>
			<div class="user_container">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapMutations
	} from 'vuex'
	export default{
		name:'User',
		data(){
			return{

			}
		},
		methods:{
			ToRoute(str){
				// console.log(str)
				this.$router.push(str)
			},
			//退出
			SignOut() {
				this.GetIndividualsShow(false)
				this.Leave()
				this.$router.push('/Home')
			},

			...mapMutations([
				'GetIndividualsShow',
				'Leave'
			])
		}
	}
</script>

<style lang="scss">
	.User{
		width: 100%;
		overflow: hidden;
		padding-top: 40px;
		display: flex;
		justify-content: space-between;
		.User_nav{
			text-align: center;
			width: 200px;
			background: rgba($color: #000000, $alpha: .2);
			border-radius: 4px;
			/*height: 100%;*/
			/*padding: 20px 0;*/
			/*float: left;*/
			.User_top{
				width: 100%;
				padding: 0 20px;
				box-sizing: border-box;
				margin-bottom: 20px;
				img{
					width: 100%;
				}
			}
			p{
				width: 100%;
				height: 50px;
				line-height: 50px;
				/*padding-left: 30px;*/
				box-sizing: border-box;
				font-size: 14px;
				cursor: pointer;
				&.active{
					border-left: 1px solid #7581EB;
					background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0) 100%);
				}
				img{
					width: 25px;
					vertical-align: middle;
					margin-bottom: 2px;
					margin-right: 10px;
				}
			}
		}
		.user_container{
			/*float: right;*/
			width: 11.5rem;
		/*	background: rgba($color: #000000, $alpha: .2);
			border-radius: 6px;*/
		}
	}
</style>
